import React from 'react'
import SEO from './SEO'
import Header from './Header'
import Footer from './Footer'
import '../assets/scss/app.scss'

const Layout = ({ location, children, pageSlug, pageTitle }) => {
  return (
    <>
      <SEO location={location} pageSlug={pageSlug} pageTitle={pageTitle} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
