import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="container copyright">
        &copy; {new Date().getFullYear()}{' '}
        <a
          href="https://fumirosato.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fumiro Sato
        </a>
        , developed by{' '}
        <a href="https://ayaos.work/" target="_blank" rel="noopener noreferrer">
          Ayaos
        </a>
        .
      </div>
    </footer>
  )
}

export default Footer
